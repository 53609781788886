import { graphql } from "gatsby"
import React from "react"
import { Container, Row } from "reactstrap"
import {
  Hero,
  Layout,
  OurCoverage,
  Tools,
} from "../../components"
import { getClassFromSlug, stripTags } from "../../lib/utils"

const Adpage = ({ data, pageContext }) => {
  const lang = data?.PageDetails?.edges[0]?.node?.fmmCore?.languageCode
  const slug = data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
  const copyrightMessage = stripTags(
    data?.FooterCopyright?.edges[0]?.node?.content
  )

  return (
    <Layout
      lang={lang}
      languages={data?.Languages?.edges}
      copyrightMessage={copyrightMessage}
      slug={slug}
      className={'AdsPage'}
    >
      <Hero
        title={data?.PageDetails?.edges[0]?.node?.hero?.title}
        intro={data?.PageDetails?.edges[0]?.node?.hero?.intro}
        linkTitle={data?.PageDetails?.edges[0]?.node?.hero?.linkLabel}
        linkUrl={data?.PageDetails?.edges[0]?.node?.hero?.linkUrl}
        imageUrl={data?.PageDetails?.edges[0]?.node?.hero?.image?.sourceUrl}
        slug={getClassFromSlug(
          data?.PageDetails?.edges[0]?.node?.fmmCore?.frontendSlug
        )}
        lang={lang}
        pageContext={pageContext}
        isHome
      />

      <section className="page-section">
        <Container>
          <Row>
            <section className="col-12 col-lg-10 mx-auto text-left text-md-center">
              <h2 className="col-12 col-xl-10 mx-auto">
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.HomeIntro?.edges[0]?.node?.title,
                  }}
                />
              </h2>

              <section
                dangerouslySetInnerHTML={{
                  __html: data?.HomeIntro?.edges[0]?.node?.content,
                }}
              />
            </section>
          </Row>
        </Container>
      </section>

      <Tools data={data?.HomeTools?.edges} />

      <section className="page-section">
        <Container>
          <Row>
            <section className="col-12 col-lg-9 mx-auto text-left text-md-center">
              <h2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data?.HomePlanning?.edges[0]?.node?.title,
                  }}
                />
              </h2>

              <section
                dangerouslySetInnerHTML={{
                  __html: data?.HomePlanning?.edges[0]?.node?.content,
                }}
              />
            </section>
          </Row>
        </Container>
      </section>

      <OurCoverage data={data?.OurCoverage?.edges} lang={lang} />

    </Layout>
  )
}

export default Adpage

export const query = graphql`
  query ($frontend_slug: String!, $language_code: String!) {
    PageDetails: allWpPage(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: $frontend_slug }
        }
      }
    ) {
      edges {
        node {
          id
          fmmCore {
            languageCode
            frontendSlug  
          }
          hero {
            title
            linkUrl
            linkLabel
            intro
            image {
              sourceUrl
            }
          }
        }
      }
    }

    Languages: allWpPage(
      filter: { fmmCore: { frontendSlug: { eq: $frontend_slug } } }
    ) {
      edges {
        node {
          id
          fmmCore {
            languageCode
            frontendSlug
          }
        }
      }
    }

    HomeIntro: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "HomeIntro" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
          }
        }
      }
    }

    HomeTools: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }

      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "HomeTools" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
          }
          generic_block_data {
            priorityOrder
            linkLabel
            priorityOrder
            linkTo {
              target
              title
              url
            }
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }

    HomePlanning: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "HomePlanning" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
          }
        }
      }
    }

    OurCoverage: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "OurCoverage" } }
      }
    ) {
      edges {
        node {
          title
          content
          fmmCore {
            frontendSlug
            languageCode
          }
          generic_block_data {
            priorityOrder
            linkLabel
            priorityOrder
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }

    FooterCopyright: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "FooterCopyright" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
